
    import {Component, Prop} from 'vue-property-decorator';
    import ActionButtons from '@/components/Category/ActionButtons/ActionButtons.vue';
    import Infos from '@/components/Category/Infos/Infos.vue';
    import FilterItem from '@/components/Category/Filter/Filter.vue';
    import List from '@/components/Category/List/List.vue';
    import NavBar from '@/components/NavBar/NavBar.vue';
    import ModalForm from '@/components/Category/Form/ModalForm/ModalForm.vue';
    import SidebarItem from '@/components/Category/Item/SidebarItem/SidebarItem.vue';
    import TopButton from '@/components/TopButton/TopButton.vue';
    import {BaseScript} from '@/components/BaseScript';
    import Common from '@/services/Common';

    @Component({
        name: 'Category',
        components: {
            ActionButtons,
            Infos,
            FilterItem,
            List,
            NavBar,
            ModalForm,
            SidebarItem,
            TopButton,
        },
    })
    export default class Category extends BaseScript {
        @Prop(Object) private readonly searchGlobalProp!: any;
        private isVisitor: boolean = Common.getIsVisitor();
    }
