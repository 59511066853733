import {Component, Prop, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import {BaseScript} from '@/components/BaseScript';
import Common from '@/services/Common';
import {FilterModel} from '@/components/Category/Filter/FilterModel';
import router from '@/router';

// modules
const category = namespace('category');

@Component({
    name: 'FilterItem',
})
export default class Filter extends BaseScript {
    private readonly COPY_CLIPBOARD_MSG = 'Copier le lien lié à ce filtre';
    private readonly COPY_CLIPBOARD_MSG_SUCCESS = 'Lien copié !';
    private data: any = new FilterModel();
    private copyClipboardMsg: string = this.COPY_CLIPBOARD_MSG;
    private resetUrl: boolean = false;
    private onLoadResetUrl: boolean = true;
    private commonFunc: any = Common;

    // listes personnalisées pour certains select
    private supportList: any = [{label: 'Dématérialisé', value: true}, {label: 'Non dématérialisé', value: false}];
    private extensionJeuList: any = ['Extension', 'Jeu'];

    @Prop(Object) private readonly searchGlobalProp!: any;

    // actions
    @category.Action('setSearchData') private readonly setSearchData!: any;

    // getters
    @category.Getter('getCollectionData') private readonly getCollectionData!: any;

    /**
     * @private
     * @return {void}
     */
    private mounted(): void {
        // on récupére les paramètres dans l'url si il y en a
        const urlParams = Common.getUrlParams();

        // SI on a un titre envoyé depuis une autre page (dans le cas des change log par exemple) on l'ajoute au filtre.
        // SINON SI on a des paramètres de filtre dans l'url, on les ajoute à notre objet
        // SINON SI notre clé est en dematerialisé, on récupére le cas spéciale du boolean
        // SINON on force le filtrage au démarage de la page, qui n'a pas besoin d'être provoqué dans le premier cas car
        // il l'est déja dans le watcher de data.title.
        if (Common.checkIsNotUndifined(this.searchGlobalProp)) {
            this.data = new FilterModel(this.searchGlobalProp);
        } else if (Object.keys(urlParams).length > 0) {
            for (const key in urlParams) {
                if (!urlParams[key]) {
                    continue;
                }
                // on vérifie si notre filtre est un string ou un number pour récupérer le premier élement du tableau
                if (Common.checkIsNotUndifined(this.data[key]) &&
                    typeof this.data[key] === 'string') {
                    this.data[key] = urlParams[key][0];
                } else if (typeof this.data[key] === 'number') {
                    this.data[key] = Number(urlParams[key][0]);
                } else if (key === 'dematerialise') {
                    if (urlParams[key][0] === 'null') {
                        continue;
                    }
                    this.data[key] = Common.stringToBoolean(urlParams[key][0]);
                } else {
                    this.data[key] = urlParams[key];
                }
            }
        } else {
            this.sendSearchData();
        }
    }

    private updated() {
        this.onLoadResetUrl = false;
    }

    /**
     * initialise les valeurs par défaut du filtre
     *
     * @private
     * @return {void}
     */
    private initData(): void {
        this.resetUrl = true;
        // reset before init
        this.data = new FilterModel();
    }

    /**
     * envoi des données au module du store et change l'url en fonction des filtres
     *
     * @private
     * @return {void}
     */
    private sendSearchData(): void {
        // pour éviter la regénération de la même url lors du chargement de la page, on vérifie avec le paramètre "onLoadResetUrl"
        if (!this.onLoadResetUrl) {
            // SI on a cliqué sur le bouton "réinitialiser le filtre", supprime tous les paramètres de l'url
            // SINON on ajoute les paramètres à l'url
            if (this.resetUrl) {
                window.history.pushState({}, document.title, window.location.pathname);
                this.resetUrl = false;
            } else {
                router.replace(Common.generateLinkWithKey(Common.formatObjectToNoUnderscore(this.data)));
            }
        }
        // on envoi les données de filtre au module category
        this.setSearchData(Common.formatObjectToNoUnderscore(this.data));
    }

    /**
     * récupération de la liste des dates
     *
     * @private
     * @return {string[]}
     */
    private get yearList(): string[] {
        const currentYear = new Date().getFullYear();
        const startYear = 1980;
        const yearList: string[] = [];

        for (let year = currentYear; year >= startYear; year--) {
            yearList.push(year.toString());
        }

        return yearList;
    }

    /**
     * Génére et copie le lien personnalisé en fonction des filtres dans le presse papier
     *
     * @private
     * @return {void}
     */
    private copyCustomLink(): void {
        navigator.clipboard.writeText(Common.generateLinkWithKey(Common.formatObjectToNoUnderscore(this.data), true)).then(() => {
            this.copyClipboardMsg = this.COPY_CLIPBOARD_MSG_SUCCESS;
            setTimeout(() => {
                this.copyClipboardMsg = this.COPY_CLIPBOARD_MSG;
            }, 2000);
        }).catch((err: any) => alert(err));
    }


    /**
     * envoi les données de filtre au module category quand on
     * modifie l'un des filtres.
     *
     * @private
     * @return {void}
     */
    @Watch('data', {deep: true})
    private dataWatch(): void {
        this.sendSearchData();
    }


    /**
     * Permet de vérifier que le nombre de joueur max est toujours supérieur ou égal au nombre de joueur minimum
     *
     * @private
     * @return {void}
     */
    private checkMaxPlayer(): void {
        if (this.data.maxPlayer < this.data.minPlayer) {
            this.data.maxPlayer = this.data.minPlayer;
        }
    }
}
