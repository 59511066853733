import {Component, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import Common from '@/services/Common';
import {BaseScript} from '@/components/BaseScript';
import {config} from '@/config/CategoryConfig';
import {CategoryFunctions} from '@/services/CategoryFunctions';
import {ItemFieldsConfig} from '@/config/ItemFieldsConfig';

// modules
const general = namespace('general');
const category = namespace('category');


@Component({
    name: 'ActionButtons',
})
export default class ActionButtons extends BaseScript {
    private modalData: any = {}; // données qui seront envoyées à la modal lors de l'action "Add"
    private itemsList: any = []; // liste des items
    private commonFunc: any = Common; // les fonctions communes à toute l'appli

    // actions
    @general.Action('setModalData') private readonly setModalData!: any;

    // getters
    @category.Getter('getItemsList') private readonly getItemsList!: any;
    @category.Getter('getCollectionData') private readonly getCollectionData!: any;
    @category.Getter('getSearchData') private readonly getSearchData!: any;

    /**
     * Vérifie que l'on ait bien getCollection pour éviter les erreurs
     *
     * @private
     * @return {void}
     */
    @Watch('getCollectionData')
    private getModalData(): void {
        if (!Common.checkIsNotUndifined(this.getCollectionData)) {
            return;
        }

        // prépare données pour la modal
        this.modalData = {
            title: 'Ajouter ' + this.getCollectionData.phrases.unSingulier,
            formName: 'ItemForm',
            formIconName: 'add',
        };
    }

    /**
     * Met à jour la liste des items utilisés pour l'export csv
     *
     * @private
     * @return {void}
     */
    @Watch('getItemsList')
    private updateItemsList(): void {
        this.itemsList = this.getItemsList;
    }

    /**
     * Créer l'export csv de la catégorie sur laquelle on se trouve
     *
     * @private
     * @return {void}
     */
    private exportToCsv(): void {
        // prépare en-tête pour le fichier CSV
        const csvData: any = [];
        let headSwitch: boolean = true;
        const tmpHead: any = [];
        const keyList: any = [];

        // on filtre les items et on prépare données pour le fichier CSV
        CategoryFunctions.filterItem(this.getSearchData, this.itemsList, this.getCollectionData).forEach((tmpItem: any) => {

            const tmpData: any = [];

            const item = new this.getCollectionData.className(tmpItem);

            delete item._id;
            delete item._rev;
            delete item._uid;
            delete item._checked;
            delete item._externalLink;
            delete item._image;

            // création de l'en-tête en fonction des key
            if (headSwitch) {
                for (const key in item) {
                    if (headSwitch) {
                        const tmpKey = key.substring(1);
                        keyList.push(tmpKey);

                        const labelList = ItemFieldsConfig.tableHeadExportCSV();

                        // si la donnée n'a pas de label défini
                        if (labelList[tmpKey] === undefined) {
                            tmpHead.push(key.substring(1));
                            continue;
                        }

                        tmpHead.push(labelList[tmpKey].label);

                        // pour les tomes, on rajoute aussi les tomes lu
                        if (tmpKey === 'tomes') {
                            tmpHead.push(labelList.tomesLu.label);
                        }
                    }
                }
            }

            // on push l'en-tête dans dans les données csv, et on met sur false le switch pour éviter de recréer
            // une autre en-tête.
            if (headSwitch) {
                csvData.push(tmpHead);
                headSwitch = false;
            }

            // on ajoute les données de l'item en fonction de l'en-tête afin de garder une harmonie dans les données
            keyList.forEach((key: string) => {
                let data = item[key];

                if (key === 'created_at' || key === 'updated_at') {
                    data = this.commonFunc.formatDate(data);
                }

                if (typeof data === 'boolean') {
                    if (data) { data = 'Oui'; } else { data = 'Non'; }
                }

                if (key === 'note' && (data === -0.5 || String(data) === '-1')) {
                    data = 'Pas de note';
                }

                if (key === 'category') {
                    data = config[data].title;
                }

                if (key === 'tomes') {
                    tmpData.push(data.nbrTome);
                    tmpData.push(data.nbrTomeLu);
                    return;
                }

                if (key === 'createdAt' || key === 'updatedAt') {
                    data = this.commonFunc.formatDate(data);
                }

                if (key === 'oeuvre' && typeof data === 'object') {
                    data = data.title;
                }

                if (key === 'comment') {
                    data = data.replaceAll(/[\n]+/g, ' || ');
                }

                tmpData.push(data);

            });

            csvData.push(tmpData);
        });

        // si aucune donnée, on ne fait pas l'export et on affiche un message.
        if (csvData.length === 0) {
            alert('Il n\'y a aucun élement à exporter.');
            return;
        }

        // encodage et téléchargement du fichier CSV.
        const csvContent = 'data:text/csv;charset=utf-8,' + '\uFEFF' + csvData.map((e: any) => e.join(';')).join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        const date = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
        link.setAttribute('download', this.getCollectionData.phrases.pluriel + '-liste-' + date + '.csv');
        document.body.appendChild(link);
        link.click();
    }
}
