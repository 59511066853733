import Common from '@/services/Common';

export class FilterModel {
    private _title: string = '';
    private _note: number = -1;
    private _realisateur: string = '';
    private _studio: string = '';
    private _developpeur: string = '';
    private _artiste: string = '';
    private _editeur: string = '';
    private _auteur: string = '';
    private _oeuvre: string = '';
    private _fabricant: string = '';
    private _label: string = '';
    private _dematerialise: boolean|null = null;
    private _extension: string|null = null;
    private _minPlayer: number = 0;
    private _maxPlayer: number = 999;
    private _tempsPartie: number = 0;
    private _etatList: string[] = [];
    private _consoleList: string[] = [];
    private _constructeurList: string[] = [];
    private _editionList: string[] = [];
    private _formatList: string[] = [];
    private _supportList: string[] = [];
    private _endDateList: string[] = [];
    private _editeurList: string|string[] = [];
    private _typeList: string[] = [];
    private _genreList: string[] = [];
    private _langueList: string[] = [];
    private _origineList: string[] = [];
    private _statutList: string[] = [];
    private _defautList: string[] = [];


    constructor(data: any = {}) {
        if (Common.checkIsNotUndifined(data.title)) { this._title = data.title; }
        if (Common.checkIsNotUndifined(data.etatList)) { this._etatList = data.etatList; }
    }

    get title(): string {
        return this._title;
    }

    set title(value: string) {
        this._title = value;
    }

    get note(): number {
        return this._note;
    }

    set note(value: number) {
        this._note = value;
    }

    get realisateur(): string {
        return this._realisateur;
    }

    set realisateur(value: string) {
        this._realisateur = value;
    }

    get studio(): string {
        return this._studio;
    }

    set studio(value: string) {
        this._studio = value;
    }

    get developpeur(): string {
        return this._developpeur;
    }

    set developpeur(value: string) {
        this._developpeur = value;
    }

    get artiste(): string {
        return this._artiste;
    }

    set artiste(value: string) {
        this._artiste = value;
    }

    get editeur(): string {
        return this._editeur;
    }

    set editeur(value: string) {
        this._editeur = value;
    }

    get auteur(): string {
        return this._auteur;
    }

    set auteur(value: string) {
        this._auteur = value;
    }

    get oeuvre(): string {
        return this._oeuvre;
    }

    set oeuvre(value: string) {
        this._oeuvre = value;
    }

    get fabricant(): string {
        return this._fabricant;
    }

    set fabricant(value: string) {
        this._fabricant = value;
    }

    get label(): string {
        return this._label;
    }

    set label(value: string) {
        this._label = value;
    }

    get dematerialise(): boolean|null {
        return this._dematerialise;
    }

    set dematerialise(value: boolean|null) {
        this._dematerialise = value;
    }

    get extension(): string | null {
        return this._extension;
    }

    set extension(value: string | null) {
        this._extension = value;
    }

    get minPlayer(): number {
        return this._minPlayer;
    }

    set minPlayer(value: number) {
        this._minPlayer = value;
    }

    get maxPlayer(): number {
        return this._maxPlayer;
    }

    set maxPlayer(value: number) {
        this._maxPlayer = value;
    }

    get tempsPartie(): number {
        return this._tempsPartie;
    }

    set tempsPartie(value: number) {
        this._tempsPartie = value;
    }

    get etatList(): string[] {
        return this._etatList;
    }

    set etatList(value: string[]) {
        this._etatList = value.sort();
    }

    get consoleList(): string[] {
        return this._consoleList;
    }

    set consoleList(value: string[]) {
        this._consoleList = value.sort();
    }

    get constructeurList(): string[] {
        return this._constructeurList;
    }

    set constructeurList(value: string[]) {
        this._constructeurList = value.sort();
    }

    get editionList(): string[] {
        return this._editionList;
    }

    set editionList(value: string[]) {
        this._editionList = value.sort();
    }

    get formatList(): string[] {
        return this._formatList;
    }

    set formatList(value: string[]) {
        this._formatList = value.sort();
    }

    get supportList(): string[] {
        return this._supportList;
    }

    set supportList(value: string[]) {
        this._supportList = value.sort();
    }

    get endDateList(): string[] {
        return this._endDateList;
    }

    set endDateList(value: string[]) {
        this._endDateList = value.sort();
    }

    get editeurList(): string | string[] {
        return this._editeurList;
    }

    set editeurList(value: string | string[]) {
        if (typeof value === 'string') {
            this._editeurList = value;
            return;
        }

        this._editeurList = value.sort();
    }

    get typeList(): string[] {
        return this._typeList;
    }

    set typeList(value: string[]) {
        this._typeList = value.sort();
    }

    get genreList(): string[] {
        return this._genreList;
    }

    set genreList(value: string[]) {
        this._genreList = value.sort();
    }

    get langueList(): string[] {
        return this._langueList;
    }

    set langueList(value: string[]) {
        this._langueList = value.sort();
    }

    get origineList(): string[] {
        return this._origineList;
    }

    set origineList(value: string[]) {
        this._origineList = value.sort();
    }

    get statutList(): string[] {
        return this._statutList;
    }

    set statutList(value: string[]) {
        this._statutList = value.sort();
    }

    get defautList(): string[] {
        return this._defautList;
    }

    set defautList(value: string[]) {
        this._defautList = value.sort();
    }
}
